import { FC } from 'react'

import { Button, Title } from 'components'
import { Link } from 'react-router-dom'

import styles from './Categories.module.scss'
import Spiner from 'components/atom/Spiner'

const Categories: FC<any> = ({ data, showDetails = true }) => {
  const subtitle = `В онлайн-каталозі  представлено близько 100 000 найменувань одягу, електроніки, меблів та інших товарів для усіх сфер життя`

  const renderCategories = data?.data.map((element: any, index: number) => (
    <a
      key={element.id}
      href={
        element?.has_child ? `/subcategories/${element.id}` : `/app/categories?category_id=${element.id}`
      }
    >
      <div className={styles.wrapper__item} key={index}>
        <div className={styles.wrapper__item__image_container}>
          <img src={element.thumb_large} alt='category' width='100%' height='100%' />
        </div>

        <p className={styles.wrapper__title}>{element.name}</p>
      </div>
    </a>
  ))

  return (
    <article className={showDetails ? styles.wrapper : ''}>
      {showDetails && <Title title='Категорії' subtitle={''} />}

      {data?.loading && showDetails ? (
        <Spiner />
      ) : (
        <>
          <div style={{ maxWidth: 'none' }} className={styles.wrapper__content}>
            {renderCategories}
          </div>

          {showDetails && (
            <Link to='/categories'>
              <Button>Дивитися усі</Button>
            </Link>
          )}
        </>
      )}
    </article>
  )
}

export default Categories
